<script setup lang="ts">
  const props = defineProps<{
    dismissable?: boolean
  }>()

  const emit = defineEmits(['dismiss'])
</script>

<template>
  <span :class="['app-chip', { 'app-chip--dismissable': dismissable }]">
    <span class="app-chip__text">
      <slot/>
    </span>
    <mdicon
      v-if="dismissable"
      @click="emit('dismiss')"
      class="app-chip__dismiss"
      width="24"
      height="24"
      name="close-circle"/>
  </span>
</template>

<style scoped>
  .app-chip {
    display: inline-flex;
    align-items: center;
    background-color: var(--c-primary);
    color: var(--c-white);
    padding: var(--gap-200) var(--gap-300);
    border-radius: 50px;
    font-size: var(--font-size-300);
    @media (--sm) {
      padding: var(--gap-100) var(--gap-250);
    }
  }

  .app-chip__dismiss {
    margin-left: var(--gap-200);
    @media (--sm) {
      margin-left: var(--gap-150);
    }
    cursor: pointer;
  }

  .app-chip--dismissable {
    padding-right: var(--gap-200);
    @media (--sm) {
      padding-right: var(--gap-150);
    }
  }

  .app-chip__text {
    position: relative;
    top: 1px;
  }
</style>
